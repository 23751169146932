import React from "react"
import { withPrefix } from "gatsby"

const DnaExplorerEssential = ({ borderColor, buttonColor }) => (
  <div className={`card border-${borderColor}`}>
    <div className="card-body text-center">
      <img
        className="img-fluid px-md-5"
        src={withPrefix("img/logo/DNA-Explorer-Essential.png")}
        alt="DNA Explorer Essential"
      />
      <p>Ideal if you are looking to get started.</p>
      <div className="text-left">
        <div className="row align-items-center mb-4">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/yellow-young.svg")}
              className="img-fluid"
              alt="skin"
            />
          </div>
          <div className="col-9">
            <h5>Skin</h5>
            <p
              className="small-explanation"
              style={{ fontWeight: "200", lineHeight: ".9rem" }}
            >
              12 traits: wrinkles, skin elasticity, collagen breakdown etc.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/yellow-burger.svg")}
              className="img-fluid"
              alt="nutrition"
            />
          </div>
          <div className="col-9">
            <h5>Nutrition</h5>
            <p className="small-explanation">
              13 traits: vitamin & mineral requirements.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/yellow-eating-together.svg")}
              className="img-fluid"
              alt="diet management"
            />
          </div>
          <div className="col-9">
            <h5>Diet Management</h5>
            <p
              className="small-explanation"
              style={{ fontWeight: "200", lineHeight: ".9rem" }}
            >
              5 traits: lactose intolerance, caffeine & alcohol metabolism,
              obesity etc
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/yellow-fitness.svg")}
              className="img-fluid"
              alt="fitness"
            />
          </div>
          <div className="col-9">
            <h5>Fitness</h5>
            <p className="small-explanation">
              6 traits: muscle potentials, recovery ability, pain tolerance etc.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-3">
            <img
              src={withPrefix("/img/illustrations/yellow-learn.svg")}
              className="img-fluid"
              alt="Inner potential"
            />
          </div>
          <div className="col-9">
            <h5>Inner Potential</h5>
            <p className="small-explanation">
              4 traits: ability to handle stress, absolute pitch, creativity
              etc.
            </p>
          </div>
        </div>
      </div>
      <hr className="mx-5" />
      <div className="price-cta">
        <div className="row align-items-center my-4">
          <div className="col-7">
            <h2 className="text-right">RM 499</h2>
          </div>
          <div className="col-5 text-left">
            <del>RM899</del>
          </div>
        </div>
        <a
          href="https://app.advanxhealth.com/products"
          className={`btn btn-explorer-${buttonColor} btn-block`}
        >
          Get It Now
        </a>
      </div>
    </div>
  </div>
)

export default DnaExplorerEssential
