import React from "react"
import { withPrefix } from "gatsby"

const Reviews = () => (
  <div className="container text-center" id="review-cards">
    <h2 className="text-center mb-5 text-white">
      Hear From Our DNA Explorer Community
    </h2>
    <p></p>
    <div className="row justify-content-center">
      <div className="col-md-3 p-2 d-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/cheryll-wong-2.png")}
            className="card-img-top pt-3 px-5"
            alt="Cheryll Wong"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Cheryll Wong</h4>
              <small>Head of Strategic Development,GoCar</small>
            </div>
            <small>
              "I want to understand my body better to achieve the ideal body
              composition. I want to be fitter and healthier."
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/michelle-chin.png")}
            className="card-img-top pt-3 px-5"
            alt="Michelle Chin"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Michelle Chin</h4>
              <small>Ride Instructor, Ministry of Burn</small>
            </div>
            <small>
              "Being able to find out more about my body through data-driven and
              scientific methodologies, to me, sounds like a no-brainer."
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/sam-shafie-2.png")}
            className="card-img-top pt-3 px-5"
            alt="Sam Shafie"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Sam Shafie</h4>
              <small>Co-founder & CEO, pitchIN</small>
            </div>
            <small>
              "I want to learn more about my health and fitness traits, so that
              I can make improvements to my lifestyle."
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-none d-md-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/richard-bradbury.png")}
            className="card-img-top pt-3 px-5"
            alt="Richard Bradbury"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Richard Bradbury</h4>
              <small>Program Host & Producer, BFM</small>
            </div>
            <small>
              "I'm interested to see if my family health history is actually
              rooted in Science or family hearsay."
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-none d-md-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/christine-wong-2.png")}
            className="card-img-top pt-3 px-5"
            alt="Christine Wong"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Christine Wong</h4>
              <small>Producer, BFM</small>
            </div>
            <small>
              "I saw DNA kits on BuzzFeed videos and was intrigued!"
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-none d-md-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/kashminder-singh.png")}
            className="card-img-top pt-3 px-5"
            alt="Kash Singh"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Kash Singh</h4>
              <small>Co-founder, pitchIN</small>
            </div>
            <small>
              "I've heard that genes affects health and one's interaction with
              food. This is a chance to find out once and for all my genetic
              traits."
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-2 d-none d-md-flex">
        <div className="card shadow-sm">
          <img
            src={withPrefix("/img/humans/jeff-sandhu.png")}
            className="card-img-top pt-3 px-5"
            alt="Jeff Sandhu"
          />
          <div className="card-body">
            <div className="mb-3">
              <h4 className="mb-0">Jeff Sandhu</h4>
              <small>Executive Producer, BFM</small>
            </div>
            <small>
              "I'm curious with the rise of medtech, and I want to find out
              what's best for my body, especially because I love Fooooooood!"
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Reviews
