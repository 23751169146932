import React from 'react'
import { Link, withPrefix } from 'gatsby'
import Layout from '../../components/layouts/layout'
import HeadMeta from '../../components/layouts/headmeta'
import ProgressBar from '../../components/layouts/progressbar'
import Reviews from '../../components/reviews'
import Faq from '../../components/faq'
import DnaExplorerEssential from '../../components/product/DnaExplorerEssential'
import DnaExplorerHealth360 from '../../components/product/DnaExplorerHealth360'
import { changeProgressBarOnScroll } from '../../components/utils/index'

export default class DNAExplorerEssentialPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Sales', "DNA Explorer Essential")
  }

  render() {
    const { faqs } = pageData
    return (
      <Layout>
        <div className="product-page" id="dna-explorer" >
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section className="hero hero-landing bg-gray" style={{ maxHeight: '90vh', backgroundImage: `url(${pageData.hero_image})`, backgroundPosition: 'center' }}>
            <div className="background-tint tint-light d-flex align-items-center">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-6 offset-lg-6 text-center text-md-right order-2 order-lg-1 p-4">
                    <img className="img-fluid" src={withPrefix('img/logo/DNA-Explorer-Essential.png')} style={{maxWidth: '200px'}} alt="DNA Explorer Essential"/>
                    <h1>Eat & Exercise <br/>According To Your DNA</h1>
                    <p>Discover your nutrition and fitness predispositions.<br/> Personalise your diet and fitness routines.<br/> Get fit and healthy based on your DNA.</p>
                    <a className="btn btn-explorer-purple" href="https://app.advanxhealth.com/products">Get it now</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div className="container">
            <div className="text-center">
              <h2>Take The First Step In Your DNA Journey With <img className="img-fluid" src={withPrefix('img/logo/DNA-Explorer-Essential.png')} style={{maxWidth: '250px'}} alt="DNA Explorer Essential"/></h2>
            </div>
              <div className="row">
                <div className="col-md-4">
                  <img src="/img/product/newbox.png" className="img-fluid" alt="Product box"/>
                </div>
                <div className="col-md-8">
                <p className="lead" style={{ fontSize: '1.4rem' }}>Your DNA + Your Lifestyle = Your Health.</p>

                  <p className="lead mt-3">
                    Blood tests, health checkups, these are all tools that can tell us what our <u>current</u> health condition is. What about your genetic predispositions?
                    <br/><br/>
                    Get a comprehensive understanding of yourself, by getting insights into the way your DNA influences your nutrition, fitness training, hidden potentials and health risks.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="extra-features" className="extra-features bg-white pt-0">
            <div className="container text-center">
              <header>
                <div className="row my-4">
                  <div className="col-md-10 offset-md-1">
                    <h2 className="text-center">What's Included In <img className="img-fluid" src={withPrefix('img/logo/DNA-Explorer-Essential.png')} style={{maxWidth: '250px'}} alt="DNA Explorer Essential"/></h2>
                  </div>
                  <div className="col-md-6 offset-md-3">
                    <p className="text-center">Get access to 40 comprehensive insights into your predispositions for nutrition traits, fitness traits, skin traits and inner potentials.</p>
                  </div>
                </div>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2 offset-md-1">
                      <img src={withPrefix('/img/illustrations/yellow-burger.svg')} className="img-fluid px-3 pb-3" alt="Nutrition"/>
                      <h4>Nutrition</h4>
                    </div>
                    <div className="col-md-2">
                      <img src={withPrefix('/img/illustrations/yellow-eating-together.svg')} className="img-fluid px-3 pb-3" alt="Diet"/>
                      <h4>Diet</h4>
                    </div>
                    <div className="col-md-2">
                      <img src={withPrefix('/img/illustrations/yellow-fitness.svg')} className="img-fluid px-3 pb-3" alt="Fitness"/>
                      <h4>Fitness</h4>
                    </div>
                    <div className="col-md-2">
                      <img src={withPrefix('/img/illustrations/yellow-young.svg')} className="img-fluid px-3 pb-3" alt="Skin"/>
                      <h4>Skin</h4>
                    </div>
                    <div className="col-md-2">
                      <img src={withPrefix('/img/illustrations/yellow-learn.svg')} className="img-fluid px-3 pb-3" alt="Inner potential"/>
                      <h4>Inner Potential</h4>
                    </div>
                  </div>
                </div>
              </header>

              <hr/>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img src={withPrefix('/img/illustrations/yellow-burger.svg')} className="img-fluid p-5" alt="Nutrition"/>
                </div>
                <div className="col-md-6 text-left">
                  <h2>Nutrition Traits</h2>
                  <p>13 reports on nutrition requirements based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Vitamin A Requirement</li>
                        <li>Vitamin B12 Requirement</li>
                        <li>Vitamin B6 Requirement</li>
                        <li>Vitamin C Requirement</li>
                        <li>Vitamin D Requirement</li>
                        <li>Vitamin E Requirement</li>
                        <li>Vitamin K Requirement</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Calcium Requirement</li>
                        <li>Carbohydrate Sensitivity</li>
                        <li>Fat Sensitivity</li>
                        <li>Folic Acid Requirement</li>
                        <li>Iodine Requirement</li>
                        <li>Iron Requirement</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img src={withPrefix('/img/illustrations/yellow-eating-together.svg')} className="img-fluid p-5" alt="diet"/>
                </div>
                <div className="col-md-6 text-left">
                  <h2>Diet Management Traits</h2>
                  <p>5 reports on diet management based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Lactose Intolerance</li>
                        <li>Caffeine Metabolism</li>
                        <li>Alcohol Metabolism</li>
                        <li>Appetite Regulation</li>
                        <li>Obesity</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img src={withPrefix('/img/illustrations/yellow-fitness.svg')} className="img-fluid p-5" alt="fitness"/>
                </div>
                <div className="col-md-6 text-left">
                  <h2>Fitness Traits</h2>
                  <p>6 reports on fitness potential and recovery ability based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Muscle Power</li>
                        <li>Muscle Strength</li>
                        <li>Muscle Endurance</li>
                        <li>Aerobic Potential</li>
                        <li>Sports Recovery Ability</li>
                        <li>Pain Tolerance</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img src={withPrefix('/img/illustrations/yellow-young.svg')} className="img-fluid p-5" alt="Skin"/>
                </div>
                <div className="col-md-6 text-left">
                  <h2>Skin Traits</h2>
                  <p>12 reports on your skin based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Skin Ageing</li>
                        <li>Wrinkle</li>
                        <li>Skin Elasticity</li>
                        <li>Antioxidant</li>
                        <li>Collagen Breakdown</li>
                        <li>Skin Glycation</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Hydration </li>
                        <li>Freckles</li>
                        <li>UV Protection</li>
                        <li>Sun Tanning</li>
                        <li>Acne Vulgaris</li>
                        <li>Dermal Sensitivity </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img src={withPrefix('/img/illustrations/yellow-learn.svg')} className="img-fluid p-5" alt="Inner potential"/>
                </div>
                <div className="col-md-6 text-left">
                  <h2>Inner Potential Traits</h2>
                  <p>4 reports on inner potential based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Ability to Handle Stress</li>
                        <li>Ability to Memorise</li>
                        <li>Absolute Pitch</li>
                        <li>Creativity</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-0">
            <Reviews />
          </section>
          <section className="how-it-works container pt-4">
            <div className="row">
              <div className="col-12 text-center">
                <h2>How It Works</h2>
                <p>From your toenails to the color of your hair, every part of your body contains genetic information.</p>
                <p>With your genetic profile, combined with your health goals and current lifestyle, you will get a personalised report with actionable insights!</p>
                <img src={withPrefix('img/product/how-it-works.jpg')} className="img-fluid px-5" align="right" alt="how it works"/>
              </div>
            </div>
          </section>
          <section className="p-0 p-md-5">
            <div className="container bg-explorer-purple py-5 my-0" style={{borderRadius: '.5rem'}}>
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2"><span className="text-left"><i className="fa fa-quote-left mb-4" style={{fontSize: '2.5rem'}}></i></span></div>
                <div className="col-md-6">
                  <h3 className="text-white text-center ">Your DNA loads the gun,<br className=""/> your lifestyle pulls the trigger.</h3>
                </div>
                <div className="col-md-1 text-right text-md-left"><span className=""><i className="fa fa-quote-right mb-4" style={{fontSize: '2.5rem'}}></i></span></div>
              </div>
            </div>
          </section>
          <section className="mb-0 bg-light" id="what-you-get">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1 text-center text-md-center">
                  <img className="img-fluid" src={withPrefix('img/logo/DNA-Explorer-Essential.png')} alt="DNA Explorer Essential"/>
                  <h4>Get comprehensive reports<br/> on 40 traits, <br/>based on your unique DNA.</h4>
                  <a href="https://app.advanxhealth.com/products" className="btn btn-explorer-pink mt-4 btn-lg">Get It Now</a>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img className="d-md-block product-gif" src={withPrefix('/img/gifs/new-dashboard.gif')} alt="Sample report"/>
                </div>
              </div>
            </div>
          </section>
          <section id="cta" className="cta bg-warning-light">
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Explore Your DNA Today.</h2>
                  <p>Uncover your genetic predispositions. Manage your lifestyle with personalised recommendations.</p>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-md-5 offset-md-1">
                  <DnaExplorerEssential borderColor="explorer-purple" buttonColor="pink" />
                </div>
                <div className="col-md-5">
                  <DnaExplorerHealth360 borderColor="none" buttonColor="purple" />
                </div>
              </div>
            </div>
          </section>
          <section id="faq">
            <Faq faqs={faqs}/>
          </section>
          <section className="py-5" >
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-4 text-center d-flex align-items-center">
                  <img src="/img/logo.png" className="img-fluid" alt="logo"/>
                </div>
                <div className="col-md-8">
                  <h2 className="mb-4">We want to empower you to take charge of your health.</h2>
                  <p className="mx-auto">First, we want to help you start by fully understanding your current health condition. Once you have a complete 360degree view of your health and wellnes, it becomes easier for you to identify the areas that requires improvement.</p>
                  <p className="mx-auto">Working towards a healthier lifestyle is not an easy journey, especially with the vast information out there catered towards all kinds of health/wellbeing.<br/> Every individual is different, which is why our reports will contain personalised information just for you.</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer Essential | Advanx Health",
    description: "Get 25 reports on nutrition and fitness based on your DNA. Ideal for those who are looking to personalise their diet and fitness routines.",
    image: "https://www.advanxhealth.com/img/share/dna-explorer.jpg",
    url: "https://www.advanxhealth.com/products/dna-explorer-essential"
  },
  hero_image: "/img/women-smiling-and-laughing-together.jpeg",
  faqs: [
    { question: "Do you need to draw blood from me?",
      answer: "No, this process does not involve drawing blood. We can obtain your DNA from the buccal epithelial (skin on your inner cheeks) cells and white blood cells found in your saliva."},
    { question: "Do I need to go to Advanx Health to give my saliva sample?",
      answer: "No, once you've made a purchase, we'll send you a saliva collection kit and you can provide your saliva sample at the comforts of your own home. Once you've collected your saliva in the given tube, all you need to do is send it back to us with the provided prepaid envelope (no extra cost!)." },
    { question: "Will saliva contain enough DNA?",
      answer: "The DNA in saliva originates from cells that are shed from the inner linings of the mouth and from white blood cells. These DNA-containing cells are collected, and the DNA is then extracted by various methods. Research has revealed that salivary DNA is equivalent in quantity and purity to DNA obtained from blood, and that the stability of salivary DNA is good when proper methods of handling are employed.<br/><small>Reference </small><br/><small>Abraham, J.E. et al. (2012). Saliva samples are a viable alternative to blood samples as a source of DNA for high throughput genotyping.</small>"},
    { question: "How long does it take for me to get my report?",
      answer: "There are many steps that we need to take to ensure that we provide the most accurate reports from your DNA, from checking if your saliva sample contain enough DNA, to scanning your DNA for SNPs. The whole process should take about 6–8 weeks once we've received the saliva sample from you." },
    { question: "How do I receive my reports?",
      answer: "Once your report is ready, you will be notified via email. Your report will be available online through your password-protected account on our platform, which you would have created when you made the purchase." },
    { question: "Why do I need to register my kit?",
      answer: "In order to tie your saliva sample to your account, you will need to register your kit once you've received the saliva collection kit. You will register the unique barcode on your account, so that we know that the saliva sample belongs to you. Our lab cannot process your saliva sample if it is not registered." },
  ]
}
